import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import Masonry from 'src/components/Masonry'
import SEO from 'src/components/seo'

import 'src/styles/home.scss'
import 'src/styles/social_media.scss'

import heart from 'src/images/prumdrt2020/heart.svg'
import comment from 'src/images/prumdrt2020/comment.svg'
import * as moment from 'moment'

import fetch from 'src/utils/fetch'

export default ({ data }) => {
  const social_media_content =
    data.allRestApiApiSocialMedia.edges[0].node.content

  const locale = data.allRestApiApiSocialMedia.edges[0].node.locale

  const [socialPosts, setSocialPosts] = useState([])
  useEffect(() => {
    fetch('social_media_data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(resultData => {
      setSocialPosts(resultData)
    })
  }, [])

  const renderIntroText = () => (
    <div className="intro-text-section">
      <div className="bold-text">
        {parse(social_media_content.share_your_photo)}
      </div>

      <div className="hash-tag-group">
        <div className="red-hash-tag">#WEDOMDRT</div>
        <div className="red-hash-tag">#ONEFORCEONEPRUDENTIAL</div>
        <div className="red-hash-tag">#RUNTOMDRT</div>
      </div>

      <div className="bold-text">
        {parse(social_media_content.have_your_stories_shared_today)}
      </div>
    </div>
  )

  const postItem = data => (
    <a className="post-item" href={data.url} target="_blank">
      <div className="post-image">
        <img src={data.image} alt="" />
      </div>
      <div className="post-description">{parse(data.description)}</div>
      <div className="bottom-info">
        <div className="like-comment-group">
          <div className="post-icons">
            <img src={heart} alt="" />
            <span>{data.total_likes}</span>
          </div>
          <div className="post-icons">
            <img src={comment} alt="" />
            <span>{data.total_comments}</span>
          </div>
        </div>
        <div className="post-timestamp">
          {moment
            .utc(data.time)
            .local()
            .fromNow()}
        </div>
      </div>
    </a>
  )

  const renderSocialPostSection = () => (
    <div className="social-post-container">
      <Masonry brakePoints={[600, 1000]}>
        {socialPosts.map(post => postItem(post))}
      </Masonry>
    </div>
  )

  return (
    <Layout locale={'cn'}>
      <SEO title="Social Media" />
      <div className="home-banner-wrapper">
        <img src={social_media_content.social_media_banner} alt="" />
        <div className="blend-line"></div>
      </div>
      <div className={`social-media-page ${locale}`}>
        {renderIntroText()}
        {renderSocialPostSection()}
      </div>
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiSocialMedia(filter: { locale: { eq: "cn" } }) {
      edges {
        node {
          locale
          content {
            social_media_banner
            share_your_photo
            have_your_stories_shared_today
          }
        }
      }
    }
  }
`
